export const villageCoeffDefaults = [
    {
        "id": "55482",
        "name": "Aghiresu",
        "coefficient": 1.05,
    },
    {
        "id": "55491",
        "name": "Aghiresu-Fabrici",
        "coefficient": 1.1,
    },
    {
        "id": "55507",
        "name": "Arghisu",
        "coefficient": 1.0,
    },
    {
        "id": "55516",
        "name": "Bagara",
        "coefficient": 1.0,
    },
    {
        "id": "55525",
        "name": "Dancu",
        "coefficient": 1.0,
    },
    {
        "id": "55534",
        "name": "Doroltu",
        "coefficient": 1.0,
    },
    {
        "id": "55543",
        "name": "Inucu",
        "coefficient": 1.0,
    },
    {
        "id": "55552",
        "name": "Leghia",
        "coefficient": 1.0,
    },
    {
        "id": "55561",
        "name": "Macau",
        "coefficient": 1.0,
    },
    {
        "id": "55570",
        "name": "Ticu",
        "coefficient": 1.0,
    },
    {
        "id": "55589",
        "name": "Ticu-Colonie",
        "coefficient": 1.0,
    },
    {
        "id": "55605",
        "name": "Aiton",
        "coefficient": 1.1,
    },
    {
        "id": "55614",
        "name": "Rediu",
        "coefficient": 1.05,
    },
    {
        "id": "55632",
        "name": "Alunis",
        "coefficient": 1.1,
    },
    {
        "id": "55641",
        "name": "Corneni",
        "coefficient": 1.05,
    },
    {
        "id": "55650",
        "name": "Ghirolt",
        "coefficient": 1.05,
    },
    {
        "id": "55669",
        "name": "Pruneni",
        "coefficient": 1.05,
    },
    {
        "id": "55678",
        "name": "Vale",
        "coefficient": 1.05,
    },
    {
        "id": "55696",
        "name": "Apahida",
        "coefficient": 1,
    },
    {
        "id": "55703",
        "name": "Bodrog",
        "coefficient": 1,
    },
    {
        "id": "55712",
        "name": "Campenesti",
        "coefficient": 1,
    },
    {
        "id": "55721",
        "name": "Corpadea",
        "coefficient": 1,
    },
    {
        "id": "55730",
        "name": "Dezmir",
        "coefficient": 1,
    },
    {
        "id": "55749",
        "name": "Pata",
        "coefficient": 1,
    },
    {
        "id": "55758",
        "name": "Sannicoara",
        "coefficient": 1,
    },
    {
        "id": "55767",
        "name": "Sub Coasta",
        "coefficient": 1,
    },
    {
        "id": "55776",
        "name": "Aschileu",
        "coefficient": 1.1
    },
    {
        "id": "55785",
        "name": "Aschileu Mare",
        "coefficient": 1,
    },
    {
        "id": "55794",
        "name": "Aschileu Mic",
        "coefficient": 1.05,
    },
    {
        "id": "55801",
        "name": "Cristorel",
        "coefficient": 1.05,
    },
    {
        "id": "55810",
        "name": "Dorna",
        "coefficient": 1.05,
    },
    {
        "id": "55829",
        "name": "Fodora",
        "coefficient": 1.05,
    },
    {
        "id": "55847",
        "name": "Baciu",
        "coefficient": 1.1,
    },
    {
        "id": "55856",
        "name": "Corusu",
        "coefficient": 1.05,
    },
    {
        "id": "55865",
        "name": "Mera",
        "coefficient": 1.05,
    },
    {
        "id": "55874",
        "name": "Popesti",
        "coefficient": 1.05,
    },
    {
        "id": "55883",
        "name": "Radaia",
        "coefficient": 1.05,
    },
    {
        "id": "55892",
        "name": "SALISTEA Noua",
        "coefficient": 1.05,
    },
    {
        "id": "55909",
        "name": "Suceagu",
        "coefficient": 1.05,
    },
    {
        "id": "55927",
        "name": "Baisoara",
        "coefficient": 1.1,
    },
    {
        "id": "55936",
        "name": "Frasinet",
        "coefficient": 1.05,
    },
    {
        "id": "55945",
        "name": "Moara de Padure",
        "coefficient": 1.05,
    },
    {
        "id": "55954",
        "name": "Muntele Baisorii",
        "coefficient": 1.05,
    },
    {
        "id": "55963",
        "name": "Muntele Bocului",
        "coefficient": 1.05,
    },
    {
        "id": "55972",
        "name": "Muntele Cacovei",
        "coefficient": 1.05,
    },
    {
        "id": "55981",
        "name": "Muntele Filii",
        "coefficient": 1.05,
    },
    {
        "id": "55990",
        "name": "Muntele Sacelului",
        "coefficient": 1.05,
    },
    {
        "id": "56005",
        "name": "Sacel",
        "coefficient": 1.05,
    },
    {
        "id": "56023",
        "name": "Belis",
        "coefficient": 1.1,
    },
    {
        "id": "56032",
        "name": "Balcesti",
        "coefficient": 1.05,
    },
    {
        "id": "56041",
        "name": "Dealu Botii",
        "coefficient": 1.05,
    },
    {
        "id": "56050",
        "name": "Giurcuta de Jos",
        "coefficient": 1.05,
    },
    {
        "id": "56069",
        "name": "Giurcuta de Sus",
        "coefficient": 1.05,
    },
    {
        "id": "56078",
        "name": "Poiana Horea",
        "coefficient": 1.05,
    },
    {
        "id": "56087",
        "name": "Smida",
        "coefficient": 1.05,
    },
    {
        "id": "56103",
        "name": "Bobalna",
        "coefficient": 1.1,
    },
    {
        "id": "56112",
        "name": "Antas",
        "coefficient": 0.95,
    },
    {
        "id": "56121",
        "name": "Babdiu",
        "coefficient": 0.95,
    },
    {
        "id": "56130",
        "name": "Blidaresti",
        "coefficient": 0.95,
    },
    {
        "id": "56149",
        "name": "Cremenea",
        "coefficient": 0.95,
    },
    {
        "id": "56158",
        "name": "Maia",
        "coefficient": 1.0,
    },
    {
        "id": "56167",
        "name": "Osorhel",
        "coefficient": 1.0,
    },
    {
        "id": "56176",
        "name": "Pruni",
        "coefficient": 0.95,
    },
    {
        "id": "56185",
        "name": "Razbuneni",
        "coefficient": 1.0,
    },
    {
        "id": "56194",
        "name": "Suaras",
        "coefficient": 0.95,
    },
    {
        "id": "56201",
        "name": "Valcelele",
        "coefficient": 0.95,
    },
    {
        "id": "56229",
        "name": "Bontida",
        "coefficient": 1.1,
    },
    {
        "id": "56238",
        "name": "Coasta",
        "coefficient": 1.05,
    },
    {
        "id": "56247",
        "name": "Rascruci",
        "coefficient": 1.05,
    },
    {
        "id": "56256",
        "name": "Tauseni",
        "coefficient": 1.05,
    },
    {
        "id": "56274",
        "name": "Borsa",
        "coefficient": 1.1,
    },
    {
        "id": "56283",
        "name": "Borsa-Catun",
        "coefficient": 1.05,
    },
    {
        "id": "56292",
        "name": "Borsa-Crestaia",
        "coefficient": 1.05,
    },
    {
        "id": "56309",
        "name": "Ciumafaia",
        "coefficient": 1.05,
    },
    {
        "id": "56318",
        "name": "Giula",
        "coefficient": 1.05,
    },
    {
        "id": "56336",
        "name": "Buza",
        "coefficient": 1.1,
    },
    {
        "id": "56345",
        "name": "Rotunda",
        "coefficient": 1.05,
    },
    {
        "id": "56363",
        "name": "Caianu",
        "coefficient": 1.1,
    },
    {
        "id": "56372",
        "name": "Barai",
        "coefficient": 1.05,
    },
    {
        "id": "56381",
        "name": "Caianu Mic",
        "coefficient": 1.05,
    },
    {
        "id": "56390",
        "name": "Caianu Vama",
        "coefficient": 1.05
    },
    {
        "id": "56407",
        "name": "Vaida Camaras",
        "coefficient": 1.05,
    },
    {
        "id": "56416",
        "name": "Valeni",
        "coefficient": 1.05,
    },
    {
        "id": "56434",
        "name": "Calarasi",
        "coefficient": 1.1,
    },
    {
        "id": "56443",
        "name": "Bogata",
        "coefficient": 1.0,
    },
    {
        "id": "56452",
        "name": "Calarasi Gara",
        "coefficient": 1.0
    },
    {
        "id": "56470",
        "name": "Calatele",
        "coefficient": 1.1,
    },
    {
        "id": "56489",
        "name": "Calata",
        "coefficient": 1.05,
    },
    {
        "id": "56498",
        "name": "Dealu Negru",
        "coefficient": 1.05,
    },
    {
        "id": "56504",
        "name": "Finciu",
        "coefficient": 1.05,
    },
    {
        "id": "56513",
        "name": "Valeni",
        "coefficient": 1.05,
    },
    {
        "id": "56531",
        "name": "Camarasu",
        "coefficient": 1.1,
    },
    {
        "id": "56540",
        "name": "Naoiu",
        "coefficient": 1.05,
    },
    {
        "id": "56559",
        "name": "Simboleni",
        "coefficient": 1.05
    },
    {
        "id": "56577",
        "name": "Capusu Mare",
        "coefficient": 1.1,
    },
    {
        "id": "56586",
        "name": "Agarbiciu",
        "coefficient": 1.05,
    },
    {
        "id": "56595",
        "name": "Balcesti",
        "coefficient": 1.05,
    },
    {
        "id": "56602",
        "name": "Capusu Mic",
        "coefficient": 1.05,
    },
    {
        "id": "56611",
        "name": "Dingau Mare",
        "coefficient": 1.05
    },
    {
        "id": "56620",
        "name": "Dingau Mic",
        "coefficient": 1.05,
    },
    {
        "id": "56639",
        "name": "Dumbrava",
        "coefficient": 1.05,
    },
    {
        "id": "56648",
        "name": "Paniceni",
        "coefficient": 1.05,
    },
    {
        "id": "56657",
        "name": "Straja",
        "coefficient": 1.05,
    },
    {
        "id": "56675",
        "name": "Caseiu",
        "coefficient": 1.1,
    },
    {
        "id": "56684",
        "name": "Comorita",
        "coefficient": 1.05,
    },
    {
        "id": "56693",
        "name": "Coplean",
        "coefficient": 1.05,
    },
    {
        "id": "56700",
        "name": "Custura",
        "coefficient": 1.05,
    },
    {
        "id": "56719",
        "name": "Garbau Dejului",
        "coefficient": 1.05,
    },
    {
        "id": "56728",
        "name": "Guga",
        "coefficient": 1.05,
    },
    {
        "id": "56737",
        "name": "Leurda",
        "coefficient": 1.05,
    },
    {
        "id": "56746",
        "name": "Rugasesti",
        "coefficient": 1.05,
    },
    {
        "id": "56755",
        "name": "Salatruc",
        "coefficient": 1.05,
    },
    {
        "id": "56764",
        "name": "Urisor",
        "coefficient": 1.05,
    },
    {
        "id": "56782",
        "name": "Catina",
        "coefficient": 1.1,
    },
    {
        "id": "56791",
        "name": "Copru",
        "coefficient": 1.05,
    },
    {
        "id": "56808",
        "name": "Feldioara",
        "coefficient": 1.05,
    },
    {
        "id": "56817",
        "name": "Hagau",
        "coefficient": 1.05,
    },
    {
        "id": "56826",
        "name": "Hodaie",
        "coefficient": 1.05,
    },
    {
        "id": "56835",
        "name": "Valea Calda",
        "coefficient": 1.05,
    },
    {
        "id": "56853",
        "name": "Ceanu Mare",
        "coefficient": 1.1,
    },
    {
        "id": "56862",
        "name": "Andici",
        "coefficient": 1.0,
    },
    {
        "id": "56871",
        "name": "Boian",
        "coefficient": 1.0,
    },
    {
        "id": "56880",
        "name": "Boldut",
        "coefficient": 1.0,
    },
    {
        "id": "56899",
        "name": "Ciurgau",
        "coefficient": 1.0,
    },
    {
        "id": "56906",
        "name": "Dosu Napului",
        "coefficient": 1.0,
    },
    {
        "id": "56915",
        "name": "Fanate",
        "coefficient": 1.0,
    },
    {
        "id": "56924",
        "name": "Hodai-Boian",
        "coefficient": 1.0,
    },
    {
        "id": "56933",
        "name": "Iacobeni",
        "coefficient": 1.0,
    },
    {
        "id": "56942",
        "name": "Mortesti",
        "coefficient": 1.0,
    },
    {
        "id": "56951",
        "name": "Stircu",
        "coefficient": 1.0,
    },
    {
        "id": "56960",
        "name": "Strucut",
        "coefficient": 1.0,
    },
    {
        "id": "56979",
        "name": "Valea lui Cati",
        "coefficient": 1.0,
    },
    {
        "id": "56997",
        "name": "Chinteni",
        "coefficient": 1.1,
    },
    {
        "id": "57001",
        "name": "Deusu",
        "coefficient": 1.0,
    },
    {
        "id": "57010",
        "name": "Feiurdeni",
        "coefficient": 1.0,
    },
    {
        "id": "57029",
        "name": "Macicasu",
        "coefficient": 1.0,
    },
    {
        "id": "57038",
        "name": "Padureni",
        "coefficient": 1.0,
    },
    {
        "id": "57047",
        "name": "Satu Lung",
        "coefficient": 1.0,
    },
    {
        "id": "57056",
        "name": "Salistea Veche",
        "coefficient": 1.0,
    },
    {
        "id": "57065",
        "name": "Sinmartin",
        "coefficient": 1.0,
    },
    {
        "id": "57074",
        "name": "Vechea",
        "coefficient": 1.0,
    },
    {
        "id": "57092",
        "name": "Chiuesti",
        "coefficient": 1.1,
    },
    {
        "id": "57109",
        "name": "Dosu Bricii",
        "coefficient": 1.05,
    },
    {
        "id": "57118",
        "name": "Huta",
        "coefficient": 1.05,
    },
    {
        "id": "57127",
        "name": "Magoaja",
        "coefficient": 1.05,
    },
    {
        "id": "57136",
        "name": "Strambu",
        "coefficient": 1.05,
    },
    {
        "id": "57145",
        "name": "Valea Casielului",
        "coefficient": 1.05,
    },
    {
        "id": "57154",
        "name": "Valea lui Opris",
        "coefficient": 1.05,
    },
    {
        "id": "57172",
        "name": "Ciucea",
        "coefficient": 1.1,
    },
    {
        "id": "57216",
        "name": "Vanatori",
        "coefficient": 1.0,
    },
    {
        "id": "57234",
        "name": "Ciurila",
        "coefficient": 1.1,
    },
    {
        "id": "57243",
        "name": "Filea de Jos",
        "coefficient": 1.0,
    },
    {
        "id": "57252",
        "name": "Filea de Sus",
        "coefficient": 1.0,
    },
    {
        "id": "57261",
        "name": "Padureni",
        "coefficient": 1.0,
    },
    {
        "id": "57270",
        "name": "Prunis",
        "coefficient": 1.0,
    },
    {
        "id": "57289",
        "name": "Salicea",
        "coefficient": 1.0,
    },
    {
        "id": "57298",
        "name": "Saliste",
        "coefficient": 1.0,
    },
    {
        "id": "57305",
        "name": "Sutu",
        "coefficient": 1.0,
    },
    {
        "id": "57323",
        "name": "Catcau",
        "coefficient": 1.1,
    },
    {
        "id": "57332",
        "name": "Muncel",
        "coefficient": 1.05,
    },
    {
        "id": "57341",
        "name": "Salisca",
        "coefficient": 1.05,
    },
    {
        "id": "57369",
        "name": "Cojocna",
        "coefficient": 1.1,
    },
    {
        "id": "57378",
        "name": "Boj Catun",
        "coefficient": 1.05,
    },
    {
        "id": "57387",
        "name": "Boju",
        "coefficient": 1.05,
    },
    {
        "id": "57396",
        "name": "Cara",
        "coefficient": 1.05,
    },
    {
        "id": "57403",
        "name": "Huci",
        "coefficient": 1.05,
    },
    {
        "id": "57412",
        "name": "Iuriu de Campie",
        "coefficient": 1.05,
    },
    {
        "id": "57421",
        "name": "Moristi",
        "coefficient": 1.05,
    },
    {
        "id": "57430",
        "name": "Straja",
        "coefficient": 1.05,
    },
    {
        "id": "57458",
        "name": "Cornesti",
        "coefficient": 1.1,
    },
    {
        "id": "57467",
        "name": "Barlea",
        "coefficient": 1.05,
    },
    {
        "id": "57476",
        "name": "Igritia",
        "coefficient": 1.0,
    },
    {
        "id": "57485",
        "name": "Lujerdiu",
        "coefficient": 1.05,
    },
    {
        "id": "57494",
        "name": "Morau",
        "coefficient": 1.05,
    },
    {
        "id": "57500",
        "name": "Stoiana",
        "coefficient": 1.05,
    },
    {
        "id": "57519",
        "name": "Tiocu de Jos",
        "coefficient": 1.0,
    },
    {
        "id": "57528",
        "name": "Tiocu de Sus",
        "coefficient": 1.0,
    },
    {
        "id": "57537",
        "name": "Tioltiur",
        "coefficient": 1.05,
    },
    {
        "id": "55071",
        "name": "Cuzdrioara",
        "coefficient": 1.1,
    },
    {
        "id": "55080",
        "name": "Manasturel",
        "coefficient": 1.05,
    },
    {
        "id": "55099",
        "name": "Valea Garboului",
        "coefficient": 1.05,
    },
    {
        "id": "57555",
        "name": "Dabaca",
        "coefficient": 1.1,
    },
    {
        "id": "57564",
        "name": "Luna de Jos",
        "coefficient": 1.0,
    },
    {
        "id": "57573",
        "name": "Paglisa",
        "coefficient": 1.0,
    },
    {
        "id": "57591",
        "name": "Feleacu",
        "coefficient": 1.1,
    },
    {
        "id": "57608",
        "name": "Casele Micesti",
        "coefficient": 0.95,
    },
    {
        "id": "57617",
        "name": "Gheorghieni",
        "coefficient": 1.0,
    },
    {
        "id": "57626",
        "name": "Saradis",
        "coefficient": 0.9,
    },
    {
        "id": "57635",
        "name": "Valcele",
        "coefficient": 1.0,
    },
    {
        "id": "57653",
        "name": "Fizesu Gherlii",
        "coefficient": 1.1,
    },
    {
        "id": "57662",
        "name": "Bont",
        "coefficient": 1.05,
    },
    {
        "id": "57671",
        "name": "Lunca Bontului",
        "coefficient": 1.05,
    },
    {
        "id": "57680",
        "name": "Nicula",
        "coefficient": 1.05,
    },
    {
        "id": "57699",
        "name": "Sacalaia",
        "coefficient": 1.05,
    },
    {
        "id": "57715",
        "name": "Floresti",
        "coefficient": 1.1,
    },
    {
        "id": "57724",
        "name": "Luna de Sus",
        "coefficient": 1.0,
    },
    {
        "id": "57733",
        "name": "Tauti",
        "coefficient": 1.0,
    },
    {
        "id": "57751",
        "name": "Frata",
        "coefficient": 1.1,
    },
    {
        "id": "57760",
        "name": "Berchiesu",
        "coefficient": 1.05,
    },
    {
        "id": "57779",
        "name": "Oas",
        "coefficient": 1.05,
    },
    {
        "id": "57788",
        "name": "Olariu",
        "coefficient": 1.05,
    },
    {
        "id": "57797",
        "name": "Padurea Iacobeni",
        "coefficient": 1.05,
    },
    {
        "id": "57804",
        "name": "Poiana Fratii",
        "coefficient": 1.05,
    },
    {
        "id": "57813",
        "name": "Razoare",
        "coefficient": 1.05,
    },
    {
        "id": "57822",
        "name": "Soporu de Campie",
        "coefficient": 1.05,
    },
    {
        "id": "57840",
        "name": "Geaca",
        "coefficient": 1.1,
    },
    {
        "id": "57859",
        "name": "Chiris",
        "coefficient": 1.05,
    },
    {
        "id": "57868",
        "name": "Lacu",
        "coefficient": 1.05,
    },
    {
        "id": "57877",
        "name": "Legii",
        "coefficient": 1.05,
    },
    {
        "id": "57886",
        "name": "Puini",
        "coefficient": 1.05,
    },
    {
        "id": "57895",
        "name": "Sucutard",
        "coefficient": 1.05,
    },
    {
        "id": "57911",
        "name": "Gilau",
        "coefficient": 1.1,
    },
    {
        "id": "57920",
        "name": "Somesu Cald",
        "coefficient": 1.0,
    },
    {
        "id": "57939",
        "name": "Somesu Rece",
        "coefficient": 1.0,
    },
    {
        "id": "57957",
        "name": "Garbau",
        "coefficient": 1.1,
    },
    {
        "id": "57458",
        "name": "Cornesti",
        "coefficient": 1.05,
    },
    {
        "id": "57975",
        "name": "Nadaselu",
        "coefficient": 1.05,
    },
    {
        "id": "57993",
        "name": "Turea",
        "coefficient": 1.05,
    },
    {
        "id": "57984",
        "name": "Vistea",
        "coefficient": 1.05,
    },
    {
        "id": "58017",
        "name": "Iara",
        "coefficient": 1.1,
    },
    {
        "id": "58026",
        "name": "Agris",
        "coefficient": 1.0,
    },
    {
        "id": "58035",
        "name": "Borzesti",
        "coefficient": 1.0,
    },
    {
        "id": "58044",
        "name": "Buru",
        "coefficient": 1.0,
    },
    {
        "id": "58053",
        "name": "Cacova Ierii",
        "coefficient": 1.0,
    },
    {
        "id": "58062",
        "name": "Fagetu Ierii",
        "coefficient": 1.0,
    },
    {
        "id": "58071",
        "name": "Lungesti",
        "coefficient": 1.0,
    },
    {
        "id": "58080",
        "name": "Masca",
        "coefficient": 1.0,
    },
    {
        "id": "58099",
        "name": "Magura Ierii",
        "coefficient": 1.0,
    },
    {
        "id": "58106",
        "name": "Ocolisel",
        "coefficient": 1.0,
    },
    {
        "id": "58115",
        "name": "Surduc",
        "coefficient": 1.0,
    },
    {
        "id": "58124",
        "name": "Valea Agrisului",
        "coefficient": 1.0,
    },
    {
        "id": "58133",
        "name": "Valea Vadului",
        "coefficient": 1.0,
    },
    {
        "id": "58151",
        "name": "Iclod",
        "coefficient": 1.1,
    },
    {
        "id": "58160",
        "name": "Fundatura",
        "coefficient": 1.05,
    },
    {
        "id": "58179",
        "name": "Iclozel",
        "coefficient": 1.05,
    },
    {
        "id": "58188",
        "name": "Livada",
        "coefficient": 1.05,
    },
    {
        "id": "58197",
        "name": "Orman",
        "coefficient": 1.05,
    },
    {
        "id": "58213",
        "name": "Izvoru Crisului",
        "coefficient": 1.1,
    },
    {
        "id": "58222",
        "name": "Nadasu",
        "coefficient": 1.05,
    },
    {
        "id": "58231",
        "name": "Nearsova",
        "coefficient": 1.05,
    },
    {
        "id": "58240",
        "name": "Saula",
        "coefficient": 1.05,
    },
    {
        "id": "55115",
        "name": "Jichisu de Jos",
        "coefficient": 1.1,
    },
    {
        "id": "55124",
        "name": "Codor",
        "coefficient": 1.0,
    },
    {
        "id": "55133",
        "name": "Jichisu de Sus",
        "coefficient": 1.0,
    },
    {
        "id": "55142",
        "name": "Sigau",
        "coefficient": 1.0,
    },
    {
        "id": "55151",
        "name": "Tarpiu",
        "coefficient": 1.0,
    },
    {
        "id": "58268",
        "name": "Jucu de Sus",
        "coefficient": 1.1,
    },
    {
        "id": "58277",
        "name": "Gadalin",
        "coefficient": 1.0,
    },
    {
        "id": "58286",
        "name": "Juc Herghelie",
        "coefficient": 1.05,
    },
    {
        "id": "58295",
        "name": "Jucu de Mijloc",
        "coefficient": 1.05,
    },
    {
        "id": "58302",
        "name": "Visea",
        "coefficient": 1.0,
    },
    {
        "id": "58320",
        "name": "Luna",
        "coefficient": 1.1,
    },
    {
        "id": "58339",
        "name": "Gligoresti",
        "coefficient": 1.05,
    },
    {
        "id": "58348",
        "name": "Luncani",
        "coefficient": 1.05,
    },
    {
        "id": "58366",
        "name": "Maguri Racatau",
        "coefficient": 1.1,
    },
    {
        "id": "58375",
        "name": "Maguri",
        "coefficient": 1.05,
    },
    {
        "id": "58384",
        "name": "Muntele Rece",
        "coefficient": 1.05,
    },
    {
        "id": "58400",
        "name": "Manastireni",
        "coefficient": 1.1,
    },
    {
        "id": "58419",
        "name": "Ardeova",
        "coefficient": 1.05,
    },
    {
        "id": "58428",
        "name": "Bedeciu",
        "coefficient": 1.05,
    },
    {
        "id": "58437",
        "name": "Bica",
        "coefficient": 1.05,
    },
    {
        "id": "58446",
        "name": "Dretea",
        "coefficient": 1.05,
    },
    {
        "id": "58455",
        "name": "Manasturu Romanesc",
        "coefficient": 1.05,
    },
    {
        "id": "58473",
        "name": "Margau",
        "coefficient": 1.1,
    },
    {
        "id": "58482",
        "name": "Bociu",
        "coefficient": 1.05,
    },
    {
        "id": "58491",
        "name": "Buteni",
        "coefficient": 1.05,
    },
    {
        "id": "58507",
        "name": "Ciuleni",
        "coefficient": 1.05,
    },
    {
        "id": "58516",
        "name": "Rachitele",
        "coefficient": 1.05,
    },
    {
        "id": "58525",
        "name": "Scrind Frasinet",
        "coefficient": 1.05,
    },
    {
        "id": "58543",
        "name": "Marisel",
        "coefficient": 1.1,
    },
    {
        "id": "55179",
        "name": "Mica",
        "coefficient": 1.1,
    },
    {
        "id": "55188",
        "name": "Dambu Mare",
        "coefficient": 1.05,
    },
    {
        "id": "55197",
        "name": "Manastirea",
        "coefficient": 1.05,
    },
    {
        "id": "55204",
        "name": "Nires",
        "coefficient": 1.05,
    },
    {
        "id": "55213",
        "name": "Sanmarghita",
        "coefficient": 1.05,
    },
    {
        "id": "55222",
        "name": "Valea Ciresoii",
        "coefficient": 1.05,
    },
    {
        "id": "55231",
        "name": "Valea Luncii",
        "coefficient": 1.05,
    },
    {
        "id": "55286",
        "name": "Mihai Viteazu",
        "coefficient": 1.1,
    },
    {
        "id": "55295",
        "name": "Cheia",
        "coefficient": 1.05,
    },
    {
        "id": "57458",
        "name": "Cornesti",
        "coefficient": 1.05,
    },
    {
        "id": "58561",
        "name": "Mintiu Gherlii",
        "coefficient": 1.1,
    },
    {
        "id": "58570",
        "name": "Bunesti",
        "coefficient": 1.05,
    },
    {
        "id": "58589",
        "name": "Nima",
        "coefficient": 1.05,
    },
    {
        "id": "58598",
        "name": "Padurenii",
        "coefficient": 1.0,
    },
    {
        "id": "58605",
        "name": "Petresti",
        "coefficient": 1.05,
    },
    {
        "id": "58614",
        "name": "Salatiu",
        "coefficient": 1.05,
    },
    {
        "id": "58632",
        "name": "Mociu",
        "coefficient": 1.1,
    },
    {
        "id": "58641",
        "name": "Boteni",
        "coefficient": 1.05,
    },
    {
        "id": "58650",
        "name": "Chesau",
        "coefficient": 1.05,
    },
    {
        "id": "58669",
        "name": "Criseni",
        "coefficient": 1.05,
    },
    {
        "id": "58678",
        "name": "Falca",
        "coefficient": 1.05,
    },
    {
        "id": "58687",
        "name": "Ghirisu Roman",
        "coefficient": 1.05,
    },
    {
        "id": "58696",
        "name": "Rosieni",
        "coefficient": 1.05,
    },
    {
        "id": "58703",
        "name": "Turmasi",
        "coefficient": 1.05,
    },
    {
        "id": "58712",
        "name": "Zorenii de Vale",
        "coefficient": 1.05,
    },
    {
        "id": "58730",
        "name": "Moldovenesti",
        "coefficient": 1.1,
    },
    {
        "id": "58749",
        "name": "Badeni",
        "coefficient": 1.05,
    },
    {
        "id": "58758",
        "name": "Pietroasa",
        "coefficient": 1.05,
    },
    {
        "id": "58767",
        "name": "Plaiesti",
        "coefficient": 1.05,
    },
    {
        "id": "58776",
        "name": "Podeni",
        "coefficient": 1.05,
    },
    {
        "id": "58785",
        "name": "Stejeris",
        "coefficient": 1.05,
    },
    {
        "id": "57190",
        "name": "Negreni",
        "coefficient": 1.1,
    },
    {
        "id": "57181",
        "name": "Bucea",
        "coefficient": 1.05,
    },
    {
        "id": "57207",
        "name": "Prelucele",
        "coefficient": 1.05,
    },
    {
        "id": "58865",
        "name": "Panticeu",
        "coefficient": 1.1,
    },
    {
        "id": "58874",
        "name": "Catalina",
        "coefficient": 1.1,
    },
    {
        "id": "58892",
        "name": "Darja",
        "coefficient": 1.1,
    },
    {
        "id": "58909",
        "name": "Sarata",
        "coefficient": 1.1,
    },
    {
        "id": "58801",
        "name": "Palatca",
        "coefficient": 1.1,
    },
    {
        "id": "58810",
        "name": "Bagaciu",
        "coefficient": 1.05,
    },
    {
        "id": "58829",
        "name": "Muresenii de Campie",
        "coefficient": 1.05,
    },
    {
        "id": "58838",
        "name": "Petea",
        "coefficient": 1.05,
    },
    {
        "id": "58847",
        "name": "Sava",
        "coefficient": 1.05,
    },
    {
        "id": "58927",
        "name": "Petrestii de Jos",
        "coefficient": 1.1,
    },
    {
        "id": "58936",
        "name": "Craesti",
        "coefficient": 1.05,
    },
    {
        "id": "58945",
        "name": "Deleni",
        "coefficient": 1.05,
    },
    {
        "id": "58954",
        "name": "Livada",
        "coefficient": 1.05,
    },
    {
        "id": "58963",
        "name": "Petrestii de Mijloc",
        "coefficient": 1.05,
    },
    {
        "id": "58972",
        "name": "Petrestii de Sus",
        "coefficient": 1.05,
    },
    {
        "id": "58981",
        "name": "Plaiuri",
        "coefficient": 1.05,
    },
    {
        "id": "59005",
        "name": "Ploscos",
        "coefficient": 1.1,
    },
    {
        "id": "59014",
        "name": "Crairat",
        "coefficient": 1.05,
    },
    {
        "id": "59023",
        "name": "Lobodas",
        "coefficient": 1.05,
    },
    {
        "id": "59032",
        "name": "Valea Florilor",
        "coefficient": 1.05,
    },
    {
        "id": "59050",
        "name": "Poieni",
        "coefficient": 1.1,
    },
    {
        "id": "59069",
        "name": "Bologa",
        "coefficient": 1.05,
    },
    {
        "id": "59078",
        "name": "Cerbesti",
        "coefficient": 1.05,
    },
    {
        "id": "59087",
        "name": "Hodisu",
        "coefficient": 1.05,
    },
    {
        "id": "59096",
        "name": "Lunca Visagului",
        "coefficient": 1.05,
    },
    {
        "id": "59103",
        "name": "Morlaca",
        "coefficient": 1.05,
    },
    {
        "id": "59112",
        "name": "Tranisu",
        "coefficient": 1.05,
    },
    {
        "id": "59121",
        "name": "Valea Draganului",
        "coefficient": 1.05,
    },
    {
        "id": "59149",
        "name": "Recea Cristur",
        "coefficient": 1.1,
    },
    {
        "id": "59158",
        "name": "Caprioara",
        "coefficient": 1.05,
    },
    {
        "id": "59167",
        "name": "Ciubanca",
        "coefficient": 1.05,
    },
    {
        "id": "59176",
        "name": "Ciubancuta",
        "coefficient": 1.05,
    },
    {
        "id": "59185",
        "name": "Elciu",
        "coefficient": 1.05,
    },
    {
        "id": "59194",
        "name": "Escu",
        "coefficient": 1.05,
    },
    {
        "id": "59201",
        "name": "Jurca",
        "coefficient": 1.05,
    },
    {
        "id": "59210",
        "name": "Osoi",
        "coefficient": 1.05,
    },
    {
        "id": "59229",
        "name": "Pustuta",
        "coefficient": 1.05,
    },
    {
        "id": "59247",
        "name": "Risca",
        "coefficient": 1.1,
    },
    {
        "id": "59256",
        "name": "Dealu Mare",
        "coefficient": 1.05,
    },
    {
        "id": "59265",
        "name": "Lapustesti",
        "coefficient": 1.05,
    },
    {
        "id": "59274",
        "name": "Marcesti",
        "coefficient": 1.05,
    },
    {
        "id": "59292",
        "name": "Sacuieu",
        "coefficient": 1.1,
    },
    {
        "id": "59309",
        "name": "Rogojel",
        "coefficient": 1.05,
    },
    {
        "id": "59318",
        "name": "Visagu",
        "coefficient": 1.05,
    },
    {
        "id": "55320",
        "name": "Sandulesti",
        "coefficient": 1.1,
    },
    {
        "id": "55339",
        "name": "Copaceni",
        "coefficient": 1.05,
    },
    {
        "id": "59336",
        "name": "Savadisla",
        "coefficient": 1.1,
    },
    {
        "id": "59345",
        "name": "Finisel",
        "coefficient": 1.05,
    },
    {
        "id": "59354",
        "name": "Hasdate",
        "coefficient": 1.05,
    },
    {
        "id": "59363",
        "name": "Lita",
        "coefficient": 1.05,
    },
    {
        "id": "59372",
        "name": "Liteni",
        "coefficient": 1.05,
    },
    {
        "id": "59381",
        "name": "Stolna",
        "coefficient": 1.05,
    },
    {
        "id": "59390",
        "name": "Valisoara",
        "coefficient": 1.05,
    },
    {
        "id": "59407",
        "name": "Vlaha",
        "coefficient": 1.05,
    },
    {
        "id": "59425",
        "name": "Sic",
        "coefficient": 1.1,
    },
    {
        "id": "59443",
        "name": "Sancraiu",
        "coefficient": 1.1,
    },
    {
        "id": "59452",
        "name": "Alunisu",
        "coefficient": 1.05,
    },
    {
        "id": "59461",
        "name": "Braisoru",
        "coefficient": 1.05,
    },
    {
        "id": "59470",
        "name": "Domosu",
        "coefficient": 1.05,
    },
    {
        "id": "59489",
        "name": "Horlacea",
        "coefficient": 1.05,
    },
    {
        "id": "59504",
        "name": "Sanmartin",
        "coefficient": 1.1,
    },
    {
        "id": "59513",
        "name": "Ceaba",
        "coefficient": 1.05,
    },
    {
        "id": "59522",
        "name": "Cutca",
        "coefficient": 1.05,
    },
    {
        "id": "59531",
        "name": "Diviciorii Mari",
        "coefficient": 1.05,
    },
    {
        "id": "59540",
        "name": "Diviciorii Mici",
        "coefficient": 1.05,
    },
    {
        "id": "59559",
        "name": "Mahal",
        "coefficient": 1.05,
    },
    {
        "id": "59568",
        "name": "Samboieni",
        "coefficient": 1.05,
    },
    {
        "id": "59577",
        "name": "Targusor",
        "coefficient": 1.05,
    },
    {
        "id": "59595",
        "name": "Sanpaul",
        "coefficient": 1.1,
    },
    {
        "id": "59602",
        "name": "Berindu",
        "coefficient": 1.05,
    },
    {
        "id": "59611",
        "name": "Mihaiesti",
        "coefficient": 1.05,
    },
    {
        "id": "59620",
        "name": "Sumurducu",
        "coefficient": 1.05,
    },
    {
        "id": "59639",
        "name": "Sardu",
        "coefficient": 1.05,
    },
    {
        "id": "59648",
        "name": "Topa Mica",
        "coefficient": 1.05,
    },
    {
        "id": "59666",
        "name": "Suatu",
        "coefficient": 1.1,
    },
    {
        "id": "59675",
        "name": "Aruncuta",
        "coefficient": 1.05,
    },
    {
        "id": "59684",
        "name": "Damburile",
        "coefficient": 1.05,
    },
    {
        "id": "59700",
        "name": "Tritenii de Jos",
        "coefficient": 1.1,
    },
    {
        "id": "59719",
        "name": "Clapa",
        "coefficient": 1.0,
    },
    {
        "id": "59728",
        "name": "Colonia",
        "coefficient": 1.0,
    },
    {
        "id": "59737",
        "name": "Padureni",
        "coefficient": 1.0,
    },
    {
        "id": "59746",
        "name": "Tritenii de Sus",
        "coefficient": 1.0,
    },
    {
        "id": "59755",
        "name": "Tritenii Hotar",
        "coefficient": 1.0,
    },
    {
        "id": "55259",
        "name": "Turda",
        "coefficient": 1,
    },
    {
        "id": "59773",
        "name": "Tureni",
        "coefficient": 1.1,
    },
    {
        "id": "59782",
        "name": "Ceanu Mic",
        "coefficient": 1.05,
    },
    {
        "id": "59791",
        "name": "Comsesti",
        "coefficient": 1.05,
    },
    {
        "id": "59808",
        "name": "Martinesti",
        "coefficient": 1.05,
    },
    {
        "id": "59817",
        "name": "Micesti",
        "coefficient": 1.05,
    },
    {
        "id": "59835",
        "name": "Taga",
        "coefficient": 1.1,
    },
    {
        "id": "59844",
        "name": "Nasal",
        "coefficient": 1.0,
    },
    {
        "id": "59853",
        "name": "Santejude",
        "coefficient": 1.0,
    },
    {
        "id": "59862",
        "name": "Santejude-Vale",
        "coefficient": 1.0,
    },
    {
        "id": "59871",
        "name": "Santioana",
        "coefficient": 1.0,
    },
    {
        "id": "59899",
        "name": "Unguras",
        "coefficient": 1.1,
    },
    {
        "id": "59906",
        "name": "Batin",
        "coefficient": 1.05,
    },
    {
        "id": "59915",
        "name": "Darot",
        "coefficient": 1.05,
    },
    {
        "id": "59924",
        "name": "Sicfa",
        "coefficient": 1.05,
    },
    {
        "id": "59933",
        "name": "Valea Ungurasului",
        "coefficient": 1.05,
    },
    {
        "id": "59951",
        "name": "Vad",
        "coefficient": 1.1,
    },
    {
        "id": "59960",
        "name": "Bogata de Jos",
        "coefficient": 1.05,
    },
    {
        "id": "59979",
        "name": "Bogata de Sus",
        "coefficient": 1.05,
    },
    {
        "id": "59988",
        "name": "Calna",
        "coefficient": 1.05,
    },
    {
        "id": "59997",
        "name": "Cetan",
        "coefficient": 1.05,
    },
    {
        "id": "60008",
        "name": "Curtuiusu Dejului",
        "coefficient": 1.05,
    },
    {
        "id": "60017",
        "name": "Valea Grosilor",
        "coefficient": 1.05,
    },
    {
        "id": "60035",
        "name": "Valea Ierii",
        "coefficient": 1.1,
    },
    {
        "id": "60044",
        "name": "Cerc",
        "coefficient": 1.05,
    },
    {
        "id": "60053",
        "name": "Plopi",
        "coefficient": 1.05,
    },
    {
        "id": "60071",
        "name": "Viisoara",
        "coefficient": 1.1,
    },
    {
        "id": "60080",
        "name": "Urca",
        "coefficient": 1.05,
    },
    {
        "id": "60106",
        "name": "Vultureni",
        "coefficient": 1.1,
    },
    {
        "id": "60115",
        "name": "Babutiu",
        "coefficient": 1.05,
    },
    {
        "id": "60124",
        "name": "Badesti",
        "coefficient": 1.05,
    },
    {
        "id": "60133",
        "name": "Chidea",
        "coefficient": 1.05,
    },
    {
        "id": "60142",
        "name": "Faureni",
        "coefficient": 1.05,
    },
    {
        "id": "60151",
        "name": "Soimeni",
        "coefficient": 1.05,
    }
]
